<template>
    <div class="d-flex flex-row w-100">
        <div class="mr-auto" :style="`width:${(adjutable ? 80 : 100)}%`">
            <v-text-field
                v-model="searchVal"
                ref="searchField"
                append-icon="mdi-magnify"
                :label="$t('common.words.search')"
                single-line
                hide-details
            />
        </div>
        <div v-if="adjutable" class="align-self-end pr-3 pb-1">
            <span>
                {{ $t(`components.search.switchLabel.${ (searchTypeSwitch ? 'text' : 'numeric') }`) }}
            </span>
        </div>
        <div v-if="adjutable">
            <v-switch hide-details v-model="searchTypeSwitch"/>
        </div>
    </div>
</template>

<script>
import Dialog from '@/components/Allgemein/Dialog'
import { mapGetters } from 'vuex'

export default {
    name: 'Search',
    components: { Dialog },
    props: {
        searchValue: {
            required: false,
            default: '',
        },
        adjutable: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            searchTypeSwitch: (this.$cookies.get('searchType') === 'true'),
        }
    },
    computed: {
        ...mapGetters([
            'getColors',
            'getAlert',
        ]),
        searchVal: {
            get() {
                return this.searchValue
            },
            set(val) {
                this.$emit('input', val)
            },
        },
    },
    watch: {
        searchTypeSwitch: {
            handler(newVal, oldVal) {
                this.$emit('emit-switch-type', newVal)
                this.$cookies.set('searchType', newVal)
            },
            immediate: true,
        },
    },
    mounted() {
        this.$refs.searchField.focus()
    }
}
</script>
